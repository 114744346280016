import { API } from "configs/api";
import baseRequest from "services/base_api";

const useAPIAuth = () => {
    // Login
    const authLoginReq = (body: APIAuth.Request, callback: (data: APIAuth.Response) => void) => {
        baseRequest({ 
            url: API.auth.login,
            content: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            },
            onSuccess: (result) => {
                const data: APIAuth.Response = JSON.parse(result) as APIAuth.Response;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Registration
    const authRegistrationReq = (body: APIAuth.Request, callback: (data: APIAuth.Response) => void) => {
        baseRequest({ 
            url: API.auth.registration,
            content: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            },
            onSuccess: (result) => {
                const data: APIAuth.Response = JSON.parse(result) as APIAuth.Response;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    return {
        authLoginReq,
        authRegistrationReq
    }
}

export default useAPIAuth