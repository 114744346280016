import { ComponentToast } from "components";
import { reload } from "configs";
import { useAPIAuth } from "services/api";
import { useData } from "storages";

const useGateway = () => {

    const { showMessageError, showMessageSuccess } = ComponentToast()
    
    const { authLoginReq, authRegistrationReq } = useAPIAuth();

    const { setToken, removeToken } = useData(); 

    // Auth Logout
    const logout = () => {
        removeToken();
        reload();
    }

    // Auth Login
    const login = (form: Auth.FormProperties) => {
        authLoginReq({
            username: form.username,
            password: form.password
        }, (data: APIAuth.Response) => {
            if (data.status) {
                setToken(data.data.token || "");
                showMessageSuccess(data.message);
                reload();
            }
            else showMessageError(data.message);
        });
    }

    // Auth Registration
    const registration = (form: Auth.FormProperties) => {
        authRegistrationReq({
            full_name: form.full_name,
            username: form.username,
            password: form.password,
        }, (data: APIAuth.Response) => {
            if (data.status) {
                setToken(data.data.token || "");
                showMessageSuccess(data.message);
                reload();
            }
            else showMessageError(data.message);
        });
    }

    return {
        logout,
        login,
        registration
    }

}

export default useGateway;