import { useState } from "react";

const useForm = () => {
    const [forms, setForms] = useState<Applications.FormProperties>({
        application_name: "",
        keys: [],
        values: []
    });

    const setFormApplicationName = (application_name: string) => {
        const temp = {
            ...forms,
            application_name
        }

        setForms(temp);
    }

    const setFormLabelKey = (pos: number, data: string) => {
        const keys = forms.keys?.map((it, i) => {
            if (i === pos) return data;
            else return it;
        }) || [];
        const temp = {
            ...forms,
            keys: keys
        }

        setForms(temp);
    }

    const setFormLabelValue = (pos: number, data: string) => {
        const values = forms.values?.map((it, i) => {
            if (i === pos) return data;
            else return it;
        });
        const temp = {
            ...forms,
            values
        }

        setForms(temp);
    }

    const addFormNewLabel = () => {
        const keys = forms.keys;
        keys?.push("");
        const values = forms.values;
        values?.push("");
        const temp = {
            ...forms,
            keys,
            values
        }

        setForms(temp);
    }

    const removeFormNewLabel = (pos: number) => {
        const keys = forms.keys;
        keys?.splice(pos, 1);
        const values = forms.values;
        values?.splice(pos, 1);
        const temp = {
            ...forms,
            keys,
            values
        }

        setForms(temp);
    }

    return {
        forms,
        
        setForms,
        setFormApplicationName,
        setFormLabelKey,
        setFormLabelValue,
        addFormNewLabel,
        removeFormNewLabel
    }
}

export default useForm;