import { Tag, TagLabel } from "@chakra-ui/react"

const ComponentItemListType1: React.FC<ItemList.Type1Properties> = ({
    label,
    isActive,
    onItemClick
}): JSX.Element => {

    return (<Tag size={"sm"} borderRadius={"full"}
        paddingTop={"6px"}
        paddingBottom={2}
        paddingX={3}
        variant={isActive ? "solid" : "none"}
        backgroundColor={isActive ? "rgba(0,0,0,0.05)" : "transparent"}
        border={"1px solid rgba(0,0,0,0.1)"}
        onClick={onItemClick}>
        <TagLabel textColor={"gray.600"}>{label}</TagLabel>
    </Tag>)
}

export default ComponentItemListType1