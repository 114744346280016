import { Box, Button, Center, Flex, GridItem, HStack, Icon, IconButton, SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import { ComponentAlert, ComponentLayoutTitle } from "components";
import { Pages, RoutePath, goto } from "configs";
import { API } from "configs/api";
import { DatasListPage } from "pages/datas";
import { FiCopy, FiEdit, FiXCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useLoad } from "services";
import { useDevice, useGateway } from "./hooks";

const DeviceDetailPage: React.FC = (): JSX.Element => {

    const { id, application_id } = useParams();
    
    const {
        isLoading, remove, detail: loadDetail
    } = useGateway();

    const { detail, setDetail } = useDevice()

    const {} = useLoad(() => {
        loadDetail((data) => {
            setDetail({
                _id: data._id,
                application_id: data.application_id,
                application_name: data.application_name,
                at: data.at,
                device_name: data.device_name,
                is_active: data.is_active,
                is_deleted: data.is_deleted,
                webhook_urls: (JSON.parse(data.webhook_urls || "") as string[]).map((it) => {
                    return it;
                }) || [],
            })
        });
    });

    return (<ComponentLayoutTitle title={Pages.devices}>
        { isLoading ? (<Box width={"full"} paddingX={4} paddingTop={4}>
            <Center>
                <Spinner size={"sm"} />
            </Center>
        </Box>) : (<VStack spacing={4} width={"full"} paddingX={4} paddingTop={4}>
            <HStack width={"full"} spacing={2}>
                <Box flex={1} textAlign={"left"}>
                    <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{detail?.device_name}</Text>
                    <Text fontSize={14} textColor={"gray.400"}>{"Created at "}{detail?.at}</Text>
                </Box>
                <ComponentAlert
                    title="Edit Device?"
                    description={`Are you sure want to edit device ${detail?.device_name}?`}
                    trigger={(<Button colorScheme="yellow" size={"sm"} leftIcon={<Icon as={FiEdit} />}>Edit</Button>)}
                    content={(<Button colorScheme="yellow" size={"sm"} leftIcon={<Icon as={FiEdit} />}
                    onClick={() => { goto(RoutePath.devices.form.replace(":application_id", application_id || "-1").replace(":id", id || "-1")) }}>Edit</Button>)} />
                <ComponentAlert
                    title="Remove Device?"
                    description={`Are you sure want to remove device ${detail?.device_name}? all of datas from this device will be deleted to!`}
                    trigger={(<Button colorScheme="red" size={"sm"} leftIcon={<Icon as={FiXCircle} />}>Delete</Button>)}
                    content={(<Button colorScheme="red" size={"sm"} leftIcon={<Icon as={FiXCircle} />}
                    onClick={() => { remove() }}>Delete</Button>)} />
            </HStack>
            <SimpleGrid gap={4} columns={2} width={"full"} borderTop={"1px solid rgba(0,0,0,0.1)"}
                paddingTop={4}>
                <GridItem>
                    <VStack spacing={4} width={"full"}>
                        <Box width={"full"}>
                            <Text fontWeight={"semibold"} fontSize={16} textColor={"gray.500"}>{"Device Details"}</Text>
                            <Text fontSize={13} textColor={"gray.400"}>{"Discover all the details about your device below"}</Text>
                        </Box>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"Device ID"}</Text>
                            </Box>
                            <Box flex={3}>
                                <Text fontSize={13} textColor={"gray.400"}>{"/:"}{detail?._id}</Text>
                            </Box>
                        </Flex>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"Webhooks"}</Text>
                            </Box>
                            <Box flex={3}>
                                {detail?.webhook_urls?.map((webhook, i) => {
                                    return (<Text key={`webhook-item-${i}`} fontSize={13} textColor={"gray.400"}>{
                                        `${webhook}`
                                    }</Text>)
                                })}
                            </Box>
                        </Flex>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack spacing={4} width={"full"}>
                        <Box width={"full"}>
                            <Text fontWeight={"semibold"} fontSize={16} textColor={"gray.500"}>{"Connect via API"}</Text>
                            <Text fontSize={13} textColor={"gray.400"}>{"Seamlessly integrate your device with out API"}</Text>
                        </Box>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"URI"}</Text>
                            </Box>
                            <Box flex={3}>
                                <Text fontSize={13} textColor={"gray.400"}>{API.devices.detail(application_id || "", id || "").substring(0, 54)}</Text>
                            </Box>
                            <IconButton aria-label="Copy Action" size={"sm"} icon={<Icon as={FiCopy} />} colorScheme="orange"
                                onClick={() => { navigator.clipboard.writeText(API.devices.detail(application_id || "", id || "")) }} />
                        </Flex>
                    </VStack>
                </GridItem>
            </SimpleGrid>
        </VStack>) }

        <DatasListPage />
    </ComponentLayoutTitle>);
}

export default DeviceDetailPage