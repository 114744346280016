import { Box, Button, HStack, Icon, Image, Text, Wrap, WrapItem } from "@chakra-ui/react"
import { FaArrowLeft, FaFilter, FaHistory, FaShoppingBag } from "react-icons/fa"
import ComponentSearchBar from "./search-bar"
import { Menus } from "configs"
import HorizontalLogo from "assets/horizontal-logo.png"

/**
 * Condition:
 * 1. Search Mode
 *      a. Back Button
 *      b. Search Bar
 *      c. Filter Button
 * 2. Logo Mode
 *      a. Back Button
 *      b. Logo Center
 * 3. Detail Mode
 *      a. Back Button
 *      b. Title
 *      c. Cart Icon
 * 4. Title Mode
 *      a. Back Button
 *      b. Title
 * 5. Normal Mode
 *      a. Search Bar
 *      b. Cart Icon
 *      c. Transaction Icon
 */

const ComponentAppBar: React.FC<AppBar.Properties> = ({
    mode,
    is_can_search,
    is_show_back,
    is_show_cart,
    title,
    onBackPress,
    onSearch
}): JSX.Element => {

    return (<HStack 
        position={"sticky"}
        left={0}
        right={0}
        top={0}
        zIndex={9999}
        backgroundColor={"white"}
        width={"full"}
        paddingX={4}
        paddingY={2}
        borderBottom={"1px solid rgba(0,0,0,0.1)"}>
            {is_show_back && (<Box paddingRight={2}
                paddingTop={1}
                cursor={"pointer"}
                onClick={onBackPress}>
                <Icon as={FaArrowLeft} color={"orange.400"} />
            </Box>)}
            {mode === "normal" && (<Image src={HorizontalLogo} maxH={14} marginRight={4} />)}
            <Box flex={1}>
                {mode === "search" && (<ComponentSearchBar 
                    placeholder={"Cari produk & lainnya"} 
                    is_can_search={is_can_search} 
                    onSearch={onSearch} />)}
                {mode === "normal" && (<Wrap>
                    {Menus.map((it, i) => {
                        return (<WrapItem key={`menu-item-${i}`}>
                            <Button colorScheme={it.path.includes(window.location.pathname) ? "orange" : "whiteAlpha"} onClick={() => {
                                window.location.assign(it.path);
                            }} textColor={it.path.includes(window.location.pathname) ? "white" : "gray.600"} fontWeight={"normal"}>{it.label}</Button>
                        </WrapItem>)
                    })}
                </Wrap>)}
                {mode === "title" && (<Text textAlign={"center"} fontSize={16} fontWeight={"semibold"} textColor={"gray.500"}>{title}</Text>)}
            </Box>
            {is_show_cart && (<Box paddingLeft={2}
                paddingTop={1}
                cursor={"pointer"}>
                    <Icon as={FaShoppingBag} fontSize={18} color={"orange.400"} />
            </Box>)}
            {mode === "normal" && (<Box paddingLeft={2}
                paddingTop={1}
                cursor={"pointer"}>
                    <Icon as={FaHistory} fontSize={18} color={"orange.400"} />
            </Box>)}
            {mode === "search" && (<Box paddingLeft={2}
                paddingTop={1}
                cursor={"pointer"}>
                    <Icon as={FaFilter} fontSize={18} color={"orange.400"} />
            </Box>)}
        </HStack>)
}

export default ComponentAppBar