import { DashboardPage, HomePage } from "pages";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Pages } from "./content";
import { ApplicationDetailPage, ApplicationFormPage, ApplicationListPage } from "pages/applications";
import { DeviceDetailPage, DeviceFormPage } from "pages/devices";
import { LoginPage, RegistrationPage } from "pages/auth";

// Router path
const RoutePath = {
    home: "/",
    auth: {
        login: "/login",
        registration: "/registration"
    },
    dashboard: "/dashboard",
    applications: {
        list: "/applications",
        form: "/applications/form/:id",
        detail: "/applications/detail/:id",
    },
    devices: {
        form: "/applications/:application_id/devices/form/:id",
        detail: "/applications/:application_id/devices/detail/:id",
    },
    widgets: {
        list: "/widgets",
        form: "/widgets/form/:id",
        detail: "/widgets/detail/:id"
    },
    products: {
        detail: "/products/detail/:id",
        categories: "/products/categories/:id",
    },
    transactions: {
        detail: "/transactions/detail/:id",
        carts: "/transactions/carts",
        histories: "/transactions/histories",
        address: "/transactions/address"
    }
}

// Create router
const router = createBrowserRouter([
    { path: RoutePath.home, element: <HomePage /> },

    { path: RoutePath.auth.login, element: <LoginPage /> },
    { path: RoutePath.auth.registration, element: <RegistrationPage /> },

    { path: RoutePath.dashboard, element: <DashboardPage /> },
    { path: RoutePath.applications.list, element: <ApplicationListPage /> },
    { path: RoutePath.applications.form, element: <ApplicationFormPage /> },
    { path: RoutePath.applications.detail, element: <ApplicationDetailPage /> },
    { path: RoutePath.devices.form, element: <DeviceFormPage /> },
    { path: RoutePath.devices.detail, element: <DeviceDetailPage /> },
]);

const Menus = [
    { path: RoutePath.dashboard, label: Pages.dashboard },
    { path: RoutePath.applications.list, label: Pages.application },
    { path: RoutePath.widgets.list, label: Pages.widgets },
]

const goto = (url: string) => {
    window.location.assign(url);
}

const reload = () => {
    window.location.reload();
}

const goback = () => {
    window.history.back();
}

const waiting = (callback: () => void) => { 
    window.setTimeout(() => {
        callback();
    }, 1000);
}

// Append to provider
const Routing: React.FC = (): JSX.Element => {
    return (<RouterProvider router={router} />)
}

// Ready to use
export {
    RoutePath, Routing, Menus, goto, reload, goback, waiting
};
