import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, Icon, Image, Spacer, Text } from "@chakra-ui/react"
import { Colors } from "configs"
import React from "react"
import { FiBell } from "react-icons/fi"

const ComponentNavigationSideBar: React.FC<Navigation.SideBarProperties> = ({
    logo,
    title,
    items,
    onItemClick,
    onNotificationToggle
}) => {
    return (<Box 
        width={"240px"} 
        maxWidth={"240px"} 
        minHeight={"100vh"}
        backgroundColor={Colors.gray}
        borderRight={"1px solid rgba(0,0,0,0.1)"}
        position={"relative"}>
        <Box 
            width={"full"}
            paddingX={3}
            paddingY={4}>
            <HStack width={"full"} spacing={3} paddingBottom={4}>
                <Image src={logo} width={"28px"} height={"28px"} borderRadius={4} />
                <Text textColor={"gray.600"} fontSize={14} fontWeight={"bold"}>{title}</Text>
                <Spacer />
                <Icon as={FiBell} cursor={"pointer"} onClick={onNotificationToggle} />
            </HStack>
            <Accordion width={"full"} allowMultiple>
                {items.map((it, i) => {
                    if (it.children) {
                        return (<AccordionItem border={"none"} key={`sidebar-item-${i}`}>
                            <AccordionButton padding={2} borderRadius={4}
                                onClick={onItemClick}
                                backgroundColor={it.isActive ? "rgba(0,0,0,0.05)" : "transparent"}>
                                <HStack width={"full"} spacing={3} paddingX={1}>
                                    {it.icon}
                                    <Box flex={1} textAlign={"left"}>
                                        <Text textColor={"gray.600"} fontSize={14} fontWeight={"semibold"}>{it.label}</Text>
                                    </Box>
                                    <AccordionIcon />
                                </HStack>
                            </AccordionButton>
                            <AccordionPanel
                                paddingY={0}
                                paddingX={5}>
                                <Box width={"full"} borderLeft={"1px solid rgba(0,0,0,0.1)"}
                                    paddingLeft={3}>
                                    {it.children.map((it2, i2) => {
                                        return (<HStack key={`sidebar-item-child-${i}-${i2}`} width={"full"} spacing={3} paddingX={2}
                                            cursor={"pointer"}
                                            paddingY={2}
                                            borderRadius={4}
                                            backgroundColor={it2.isActive ? "rgba(0,0,0,0.05)" : "transparent"}
                                            _hover={{
                                                backgroundColor: "rgba(0,0,0,0.05)"
                                            }}>
                                            <Box flex={1} textAlign={"left"}>
                                                <Text textColor={"gray.600"} fontSize={14} fontWeight={"semibold"}>{it2.label}</Text>
                                            </Box>
                                        </HStack>)
                                    })}
                                </Box>
                            </AccordionPanel>
                        </AccordionItem>)
                    }

                    return (<AccordionItem border={"none"} key={`sidebar-item-${i}`}>
                        <AccordionButton padding={2} backgroundColor={it.isActive ? "rgba(0,0,0,0.05)" : "transparent"} borderRadius={4}
                            onClick={onItemClick}>
                            <HStack width={"full"} spacing={3} paddingX={1}>
                                {it.icon}
                                <Box flex={1} textAlign={"left"}>
                                    <Text textColor={"gray.600"} fontSize={14} fontWeight={"semibold"}>{it.label}</Text>
                                </Box>
                            </HStack>
                        </AccordionButton>
                    </AccordionItem>)
                })}
            </Accordion>
        </Box>
    </Box>)
}

export default ComponentNavigationSideBar