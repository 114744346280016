import {
  ChakraProvider,
  theme
} from "@chakra-ui/react"
import { Routing } from "configs"

export const App = () => (
  <ChakraProvider theme={theme}>
    <Routing />
  </ChakraProvider>
)
