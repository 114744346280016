import { Box, Button, Center, FormControl, FormLabel, GridItem, HStack, Icon, IconButton, Input, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { ComponentLayoutTitle, ComponentTable, ComponentToast } from "components";
import { ContentPage, Pages } from "configs";
import { FiPlus, FiSave, FiXCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useLoad } from "services";
import { useForm, useGateway } from "./hooks";

const DeviceFormPage: React.FC = (): JSX.Element => {

    const { id } = useParams();
    const { showMessageWarning } = ComponentToast();
    const {
        forms,
        
        setForms,
        setFormDeviceName,
        setFormWebhook,
        addFormWebhook,
        removeFormWebhook
    } = useForm();
    const {
        isLoading, create, update, detail: loadDetail
    } = useGateway();

    const mode = (id) ? (id === "-1") ? "new" : "edit" : "new";

    const {} = useLoad(() => {
        if (mode === "edit") {
            loadDetail((data) => {
                setForms({
                    device_name: data.device_name,
                    webhooks: (JSON.parse(data.webhook_urls || "") as string[]).map((it) => {
                        return it;
                    }) || [],
                })
            });
        }
    });

    const onSubmit = () => {
        // Doing sanity check
        if (forms.device_name === undefined) return showMessageWarning("Please fill device name");
        else if (forms.device_name.length === 0) return showMessageWarning("Please fill device name");
        else {
            if (mode === "new") create(forms);
            else update(forms);
        }
    }

    return (<ComponentLayoutTitle title={Pages.devices}>
        <VStack spacing={4} width={"full"} paddingX={4} paddingTop={4}>
            <HStack width={"full"}>
                <Box flex={1} textAlign={"left"}>
                    <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{mode === "new" ? ContentPage.devices.title.new : ContentPage.devices.title.edit}</Text>
                    <Text fontSize={14} textColor={"gray.400"}>{ContentPage.devices.description.form}</Text>
                </Box>
                <Button colorScheme="orange" size={"sm"} leftIcon={<Icon as={FiSave} />}
                    onClick={onSubmit}>Submit</Button>
            </HStack>
            <SimpleGrid gap={4} columns={2} width={"full"} borderTop={"1px solid rgba(0,0,0,0.1)"}
                paddingTop={4}>
                <GridItem>
                    <FormControl isRequired={true}>
                        <FormLabel fontWeight={"semibold"} fontSize={16} textColor={"gray.600"} padding={0} margin={0}>Device Name</FormLabel>
                        <Text fontSize={13} textColor={"gray.400"} marginBottom={2}>Insert your device name</Text>
                        <Input placeholder="Insert your device name here..." 
                            value={forms.device_name}
                            onChange={(e) => setFormDeviceName(e.target.value)} />
                    </FormControl>
                </GridItem>
            </SimpleGrid>
        </VStack>

        <ComponentTable
            title={"Webhooks"}
            description={"Insert the webhook url you want linking to your device"}
            header={(<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>ID</Text>
                </Box>
                <Box flex={3}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Webhook URL</Text>
                </Box>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Action</Text>
                </Box>
            </HStack>)}
            body={(forms.webhooks?.length || 0) > 0 ? forms.webhooks?.map((webhook, i) => {

                return (<HStack key={`item-form-webhook-item-${i}`} width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                    <Box flex={1}>
                        <Text fontSize={14} textColor={"gray.400"}>{(i + 1)}</Text>
                    </Box>
                    <Box flex={3}>
                        <FormControl maxWidth={"360px"}>
                            <Input placeholder="Input webhook url here..." 
                                value={webhook} 
                                onChange={(e) => setFormWebhook(i, e.target.value)} />
                        </FormControl>
                    </Box>
                    <HStack spacing={1} flex={1}>
                        <IconButton size={"sm"}
                            aria-label="New Action"
                            icon={<Icon as={FiPlus} />}
                            colorScheme="green"
                            onClick={addFormWebhook} />
                        <IconButton size={"sm"}
                            aria-label="Delete Action"
                            icon={<Icon as={FiXCircle} />}
                            colorScheme="red"
                            onClick={() => removeFormWebhook(i)} />
                    </HStack>
                </HStack>);
            }): <Center height={20} backgroundColor={"gray.50"} width={"full"}
                cursor={"pointer"}
                onClick={addFormWebhook}>
                <HStack>
                    <Icon as={FiPlus} />
                    <Text>Create new webhook</Text>
                </HStack>
            </Center>} />
    </ComponentLayoutTitle>);
}

export default DeviceFormPage