import { VStack } from "@chakra-ui/react";
import { ComponentAnnounce, ComponentItemListType2 } from "components";
import React from "react";

const QuickAction: React.FC = () => {
    return (<ComponentAnnounce
        title="Aksi Cepat"
        description="Berikut daftar tindakan yang perlu dilakukan secara cepat"
        content={(<VStack width={"full"} paddingTop={2}>
            <ComponentItemListType2
                title="4 Order Siap Dikirim"
                description="Terdapat 4 transaksi yang sudah melakukan pembayaran dan siap untuk di proses."
                onItemClick={() => {}} />
            <ComponentItemListType2
                title="2 Order Belum Lunas"
                description="Terdapat 2 transaksi yang masuk dan perlu di ingatkan kembali untuk melakukan pembayaran."
                onItemClick={() => {}} />
            <ComponentItemListType2
                title="8 Produk Sudah Habis"
                description="Terdapat 8 produk yang stoknya kosong, ayo re-stok lagi agar customer tidak menunggu."
                onItemClick={() => {}} />
        </VStack>)} />)
}

export default QuickAction;