import { Box } from "@chakra-ui/react";
import ComponentAppBar from "components/app-bar";
import { RoutePath, goto } from "configs";
import { useEffect } from "react";
import { useData } from "storages";

const ComponentLayoutTitle: React.FC<Layout.TitleProperties> = ({
    title,
    children
}): JSX.Element => {

    const { isLogged } = useData();

    useEffect(() => {
        if (isLogged !== undefined) {
            if (!isLogged) goto(RoutePath.auth.login);
        }
    }, [isLogged])

    return (<Box 
        maxWidth={"container.lg"}
        minWidth={"container.lg"}
        marginX={"auto"}
        minHeight={"100vh"}
        backgroundColor={"white"}
        boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
            <ComponentAppBar mode="title"
                is_can_search={false}
                is_show_back={true}
                is_show_cart={false}
                title={title}
                onBackPress={() => window.history.back()} />
            
            {children}
    </Box>);
}

export default ComponentLayoutTitle