import { Box, HStack, Icon, Text } from "@chakra-ui/react"

import { SingleProperties } from "types/count-bar"

const ComponentCountBarSingle: React.FC<SingleProperties> = ({
    label,
    count,
    icon,
    onMore
}): JSX.Element => {

    return (<Box flex={1} padding={4} boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
        <HStack width={"full"}>
            <Box flex={1}>
                <Text fontSize={16} textColor={"gray.500"}>{label}</Text>
                <Text fontSize={24} fontWeight={"semibold"} textColor={"gray.600"}>{count}</Text>
                <Text fontSize={13} textColor={"gray.400"} cursor={"pointer"} onClick={onMore}>See more details</Text>
            </Box>
            <Box padding={2} backgroundColor={"gray.50"} borderRadius={"full"} width={16} height={16}
            textAlign={"center"}>
                {icon && (<Icon as={icon} fontSize={32}
                    marginTop={2} textColor={"gray.600"} />)}
            </Box>
        </HStack>
    </Box>)
}

export default ComponentCountBarSingle