import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react"
import React from "react"
import { FiChevronDown } from "react-icons/fi"

const ComponentNavigationDropdown: React.FC<Navigation.DropdownProperties> = ({
    label,
    items
}) => {
    return (<Menu>
        <MenuButton as={Button}
            variant={"outline"} 
            size={"sm"}
            rightIcon={<FiChevronDown />}>
            {label}
        </MenuButton>
        <MenuList>
            {items.map((it, i) => {
                return (<MenuItem key={`item-dropdown-${label}-${i}`}
                    onClick={it.onItemClick}>
                    {it.label}
                </MenuItem>)
            })}
        </MenuList>
    </Menu>)
}

export default ComponentNavigationDropdown