import { API } from "configs/api";
import baseRequest from "services/base_api";
import { useData } from "storages";

const useAPIApplications = () => {
    const { getToken } = useData();

    // Create Application
    const applicationCreateReq = (body: APIApplications.Request, callback: (data: APIApplications.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.applications.create,
            content: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
                body: JSON.stringify(body)
            },
            onSuccess: (result) => {
                const data: APIApplications.ResponseWrite = JSON.parse(result) as APIApplications.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Update Application
    const applicationUpdateReq = (_id: string, body: APIApplications.Request, callback: (data: APIApplications.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.applications.update(_id),
            content: {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
                body: JSON.stringify(body)
            },
            onSuccess: (result) => {
                const data: APIApplications.ResponseWrite = JSON.parse(result) as APIApplications.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Delete Application
    const applicationDeleteReq = (_id: string, callback: (data: APIApplications.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.applications.delete(_id),
            content: {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIApplications.ResponseWrite = JSON.parse(result) as APIApplications.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Detail Application
    const applicationDetailReq = (_id: string, callback: (data: APIApplications.ResponseDetail) => void) => {
        baseRequest({ 
            url: API.applications.detail(_id),
            content: {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIApplications.ResponseDetail = JSON.parse(result) as APIApplications.ResponseDetail;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // List Application
    const applicationListReq = (callback: (data: APIApplications.ResponseList) => void) => {
        baseRequest({ 
            url: API.applications.list,
            content: {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIApplications.ResponseList = JSON.parse(result) as APIApplications.ResponseList;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    return {
        applicationCreateReq,
        applicationDeleteReq,
        applicationDetailReq,
        applicationListReq,
        applicationUpdateReq,
    }
}

export default useAPIApplications