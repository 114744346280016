import { FiBox, FiDownload, FiLock, FiMessageCircle, FiPlus, FiUser, FiUsers } from "react-icons/fi"

const Pages = {
    dashboard: "Dashboard",
    application: "Applications",
    devices: "Devices",
    widgets: "Widgets",
    profile: "Profile",
    login: "Log In",
    registration: "Registration",
}

const ContentPage = {
    auth: {
        title: {
            login: "Sign In",
            registration: "Create Account",
        },
        description: {
            login: "Do not have an account?",
            registration: "Already have an account?"
        },
        forms: {
            username: {
                is_required: true,
                icon: FiUser,
                label: "Username",
                placeholder: "Fill your username here"
            },
            password: {
                is_required: true,
                icon: FiLock,
                label: "Password",
                placeholder: "Fill your secure password here"
            },
            full_name: {
                is_required: true,
                icon: undefined,
                label: "Full Name",
                placeholder: "Fill your full name here"
            }
        },
        button: {
            login: "Sign In",
            registration: "Sign Up"
        }
    },
    dashboard: {
        heading: "Welcome back, ",
        counting_bar: {
            applications: {
                label: "Total Application",
                icon: FiBox
            },
            api_keys: {
                label: "Total Api Keys",
                icon: FiUsers
            },
            devices: {
                label: "Device Management",
                description: "You can monitor the status of all devices you use here.",
            },
            articles: {
                label: "Articles",
                description: "You can see the number of articles you have here.",
            }
        },
        helping_widget: {
            label: "Need Help?",
            description: "If you have any technical problems or questions regarding Amethyst Platform, don’t hesitate to contact our support team below.",
            button: {
                label: "Contact Our Team",
                icon: FiMessageCircle
            }
        },
        documentation_widget: {
            label: "Documentation",
            description: "If you want to using Amethyst properly.",
            items: [
                { label: "Getting Started", description: "Basic step to create device on Amethyst" },
                { label: "Learn API", description: "Learn API for store data on Amethyst" },
                { label: "Developer Guide", description: "Sample code that can be use to your existing business" },
            ]
        }
    },
    applications: {
        heading: "You can view, create, and edit your application on this page.",
        actions: {
            search: "Search application here...",
            new: FiPlus,
        },
        title: {
            list: "Application List",
            new: "Application New",
            edit: "Application Edit",
        },
        description: {
            list: "List of application in your account. You can see the details and manage each application.",
            form: "Please fill the form about your application.",
        }
    },
    devices: {
        title: {
            list: "Device List",
            new: "Device New",
            edit: "Device Edit",
        },
        description: {
            list: "List of devices in your application.",
            form: "Please fill the form about your device.",
        },
        actions: {
            search: "Search device here...",
            new: FiPlus,
        },
    },
    datas: {
        title: {
            list: "Data List"
        },
        description: {
            list: "List of data obtained from your device."
        },
        actions: {
            search: "Search data here...",
            download: FiDownload,
        },
    }
}

export {
    Pages,
    ContentPage
}