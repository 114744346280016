import { Box, Button, Center, FormControl, FormLabel, GridItem, HStack, Icon, Image, Input, InputGroup, InputLeftElement, SimpleGrid, Text, VStack } from "@chakra-ui/react"
import { ComponentLayoutCenter, ComponentToast } from "components"
import { Colors, ContentPage, Logo, Pages, RoutePath, goto } from "configs"
import { FiLogIn } from "react-icons/fi"
import { useForm, useGateway } from "./hooks"
import { useMeta } from "utilities"

const LoginPage: React.FC = (): JSX.Element => {

    const { setTitle } = useMeta();
    const { showMessageWarning } = ComponentToast();
    const {
        forms,

        setFormPassword,
        setFormUsername
    } = useForm();
    const { login } = useGateway();

    setTitle(Pages.login)

    const onSubmit = () => {
        if (forms.username === undefined) return showMessageWarning("Please fill username");
        else if (forms.username.length === 0) return showMessageWarning("Please fill username");
        else if (forms.password === undefined) return showMessageWarning("Please fill password");
        else if (forms.password.length === 0) return showMessageWarning("Please fill password");
        else login(forms);
    }

    return (<ComponentLayoutCenter>
        
        <Center paddingY={4}>
            <Image src={Logo} maxHeight={"64px"} />
        </Center>

        <SimpleGrid 
            width={"md"} 
            backgroundColor={"white"}
            columns={1}
            gap={4}
            boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
                <GridItem>
                    <VStack width={"full"} spacing={2} paddingX={8} paddingY={4}>
                        <Box width={"full"}>
                            <Text fontSize={28} fontWeight={"bold"} textColor={"gray.600"} textAlign={"left"}>
                                {ContentPage.auth.title.login}
                            </Text>
                        </Box>
                        <Box width={"full"}>
                            <Text fontSize={16} textColor={"gray.500"} textAlign={"left"}>
                                {ContentPage.auth.description.login} <strong style={{
                                    color: Colors.main,
                                    cursor: "pointer"
                                }} onClick={() => goto(RoutePath.auth.registration)}>
                                    {ContentPage.auth.button.registration}
                                </strong>
                            </Text>
                        </Box>
                        <FormControl width={"full"} isRequired={ContentPage.auth.forms.username.is_required}>
                            <FormLabel fontSize={16} fontWeight={"semibold"} textColor={"gray.500"}>
                                {ContentPage.auth.forms.username.label}
                            </FormLabel>
                            <InputGroup>
                                <InputLeftElement>
                                    <Icon as={ContentPage.auth.forms.username.icon} fontSize={16} textColor={Colors.main} />
                                </InputLeftElement>
                                <Input type="text" placeholder={ContentPage.auth.forms.username.placeholder}
                                    onChange={(e) => setFormUsername(e.target.value)} />
                            </InputGroup>
                        </FormControl>
                        <FormControl width={"full"} isRequired={ContentPage.auth.forms.password.is_required}>
                            <FormLabel fontSize={16} fontWeight={"semibold"} textColor={"gray.500"}>
                                {ContentPage.auth.forms.password.label}
                            </FormLabel>
                            <InputGroup>
                                <InputLeftElement>
                                    <Icon as={ContentPage.auth.forms.password.icon} fontSize={16} textColor={Colors.main} />
                                </InputLeftElement>
                                <Input type="password" placeholder={ContentPage.auth.forms.password.placeholder}
                                    onChange={(e) => setFormPassword(e.target.value)} />
                            </InputGroup>
                        </FormControl>
                        <HStack width={"full"} marginBottom={2}>
                            <Box flex={1}>
                                <Text cursor={"pointer"} textColor={Colors.main} fontSize={16}>Forgot Password?</Text>
                            </Box>
                            <Button size={"sm"} backgroundColor={Colors.main} textColor={"white"}
                                leftIcon={<Icon as={FiLogIn} />}
                                onClick={onSubmit}>
                                {ContentPage.auth.button.login}
                            </Button>
                        </HStack>
                    </VStack>
                </GridItem>
        </SimpleGrid>
    </ComponentLayoutCenter>)
}

export default LoginPage