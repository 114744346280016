import { API } from "configs/api";
import baseRequest from "services/base_api";
import { useData } from "storages";

const useAPIDevices = () => {
    const { getToken } = useData();

    // Create Device
    const deviceCreateReq = (application_id: string, body: APIDevices.Request, callback: (data: APIDevices.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.devices.create(application_id),
            content: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
                body: JSON.stringify(body)
            },
            onSuccess: (result) => {
                const data: APIDevices.ResponseWrite = JSON.parse(result) as APIDevices.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Update Device
    const deviceUpdateReq = (application_id: string, _id: string, body: APIDevices.Request, callback: (data: APIDevices.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.devices.update(application_id, _id),
            content: {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
                body: JSON.stringify(body)
            },
            onSuccess: (result) => {
                const data: APIDevices.ResponseWrite = JSON.parse(result) as APIDevices.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Delete Device
    const deviceDeleteReq = (application_id: string, _id: string, callback: (data: APIDevices.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.devices.delete(application_id, _id),
            content: {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIDevices.ResponseWrite = JSON.parse(result) as APIDevices.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // Detail Device
    const deviceDetailReq = (application_id: string, _id: string, callback: (data: APIDevices.ResponseDetail) => void) => {
        baseRequest({ 
            url: API.devices.detail(application_id, _id),
            content: {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIDevices.ResponseDetail = JSON.parse(result) as APIDevices.ResponseDetail;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // List Device
    const deviceListReq = (application_id: string, callback: (data: APIDevices.ResponseList) => void) => {
        baseRequest({ 
            url: API.devices.list(application_id),
            content: {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIDevices.ResponseList = JSON.parse(result) as APIDevices.ResponseList;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    return {
        deviceCreateReq,
        deviceDeleteReq,
        deviceDetailReq,
        deviceListReq,
        deviceUpdateReq
    }
}

export default useAPIDevices