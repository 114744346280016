import { LS_FirstAccess, LS_Login } from "configs/";
import { useState } from "react";
import { useLoad } from "services";
import useLocalStorage from "storages/local/useLocalStorage";

const useData = () => {
    const { getString, save, remove } = useLocalStorage();

    const [isLogged, setIsLogged] = useState<boolean>();

    const {} = useLoad(() => {
        setIsLogged(getToken() !== "");
    });

    const getToken = (): string => {
        const token = getString(LS_Login) || "";
        return token;
    }

    const setToken = (token: string) => {
        save(LS_Login, token);
    }

    const removeToken = () => {
        remove(LS_Login);
    }

    const setFirstAccess = () => {
        const date = Date.now().toString();
        save(LS_FirstAccess, date);
        window.location.reload();
    }

    const isFirstAccess = () => {
        const dateFirstAccess = getString(LS_FirstAccess) || "";
        return dateFirstAccess === "";
    }

    return {
        isLogged,

        getToken,
        setToken,
        removeToken,
        setFirstAccess,
        isFirstAccess
    }
}

export {
    useLocalStorage,
    useData
};