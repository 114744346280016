import { useToast } from "@chakra-ui/react";

const ComponentToast = () => {
    const toast = useToast();

    const showMessageError = (message: string) => {
        toast({
            status: "error",
            title: "Terjadi kesalahan",
            description: message
        });
    }

    const showMessageWarning = (message: string) => {
        toast({
            status: "warning",
            title: "Perhatian",
            description: message
        });
    }

    const showMessageSuccess = (message: string) => {
        toast({
            status: "success",
            title: "Berhasil",
            description: message
        });
    }

    return {
        showMessageError,
        showMessageWarning,
        showMessageSuccess
    }
}

export default ComponentToast;