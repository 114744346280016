import { useState } from "react";

const useForm = () => {
    const [forms, setForms] = useState<Devices.FormProperties>({
        device_name: "",
        webhooks: []
    });

    const setFormDeviceName = (device_name: string) => {
        const temp = {
            ...forms,
            device_name
        }

        setForms(temp);
    }

    const setFormWebhook = (pos: number, data: string) => {
        const webhooks = forms.webhooks?.map((it, i) => {
            if (i === pos) return data;
            else return it;
        }) || [];
        const temp = {
            ...forms,
            webhooks
        }

        setForms(temp);
    }

    const addFormWebhook = () => {
        const webhooks = forms.webhooks;
        webhooks?.push("");
        const temp = {
            ...forms,
            webhooks
        }

        setForms(temp);
    }

    const removeFormWebhook = (pos: number) => {
        const webhooks = forms.webhooks;
        webhooks?.splice(pos, 1);
        const temp = {
            ...forms,
            webhooks
        }

        setForms(temp);
    }

    return {
        forms,
        
        setForms,
        setFormDeviceName,
        setFormWebhook,
        addFormWebhook,
        removeFormWebhook
    }
}

export default useForm;