import { ComponentToast } from "components";
import { goback, waiting } from "configs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAPIDevices } from "services/api";

const useGateway = () => {

    const { showMessageError, showMessageSuccess } = ComponentToast()
    
    const { deviceCreateReq, deviceDeleteReq, deviceDetailReq, deviceListReq, deviceUpdateReq } = useAPIDevices();

    const { id, application_id } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    // Create Device
    const create = (form: Devices.FormProperties) => {
        if (application_id && application_id !== "-1") {
            setIsLoading(true);
            deviceCreateReq(application_id, {
                device_name: form.device_name,
                webhook_urls: form.webhooks    
            }, (data: APIDevices.ResponseWrite) => {
                if (data.status) {
                    showMessageSuccess(data.message);
                    waiting(goback);
                }
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Update Application
    const update = (form: Devices.FormProperties) => {
        if (id && application_id && application_id !== "-1" && id !== "-1") {
            setIsLoading(true);
            deviceUpdateReq(application_id, id, {
                device_name: form.device_name,
                webhook_urls: form.webhooks   
            }, (data: APIDevices.ResponseWrite) => {
                if (data.status) {
                    showMessageSuccess(data.message);
                    waiting(goback);
                }
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Remove Application
    const remove = () => {
        if (id && application_id && application_id !== "-1" && id !== "-1") {
            setIsLoading(true);
            deviceDeleteReq(application_id, id, (data: APIDevices.ResponseWrite) => {
                if (data.status) {
                    showMessageSuccess(data.message);
                    waiting(goback);
                }
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Detail Application
    const detail = (onLoad: (data: APIDevices.DataDetail) => void) => {
        if (id && application_id && application_id !== "-1" && id !== "-1") {
            setIsLoading(true);
            deviceDetailReq(application_id, id, (data: APIDevices.ResponseDetail) => {
                if (data.status) onLoad(data.data);
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Detail Application
    const list = (onLoad: (data: APIDevices.DataList[]) => void) => {
        if (application_id || id) {
            setIsLoading(true);
            deviceListReq(application_id || id || "", (data: APIDevices.ResponseList) => {
                if (data.status) onLoad(data.data);
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    return {
        isLoading,
        
        create,
        update,
        remove,
        detail,
        list
    }

}

export default useGateway;