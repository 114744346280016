import { Box, Button, Center, HStack, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, Tag, Text, VStack } from "@chakra-ui/react";
import { ComponentLayoutDashboard, ComponentTable } from "components";
import { ContentPage, Pages, RoutePath, goto } from "configs";
import { FiEye } from "react-icons/fi";
import { useApplication, useGateway } from "./hooks";
import { useLoad } from "services";

const ApplicationListPage: React.FC = (): JSX.Element => {

    const { isLoading, list: loadList } = useGateway();
    const { list, setList } = useApplication();

    const {} = useLoad(() => {
        loadList((data) => {
            setList(data);
        });
    });

    return (<ComponentLayoutDashboard>
        <VStack spacing={4} width={"full"} paddingX={4} paddingTop={4}>
            <Box width={"full"} textAlign={"left"}>
                <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{Pages.application}</Text>
                <Text fontSize={14} textColor={"gray.400"}>{ContentPage.applications.heading}</Text>
            </Box>
        </VStack>

        <ComponentTable
            title={ContentPage.applications.title.list}
            description={ContentPage.applications.description.list}
            search={{
                placeholder: ContentPage.applications.actions.search,
                onSearch: (query: string) => {}
            }}
            action={{
                label: "New",
                onClick: () => { goto(RoutePath.applications.form.replace(":id", "-1")) }
            }}
            header={(<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>URI</Text>
                </Box>
                <Box flex={3}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Application Name</Text>
                </Box>
                <Box flex={2}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Last Update</Text>
                </Box>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Status</Text>
                </Box>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>More</Text>
                </Box>
            </HStack>)}
            body={isLoading ? (<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
            paddingBottom={2}>
                <Box flex={1}>
                    <Center>
                        <Spinner size={"sm"} />
                    </Center>
                </Box>
            </HStack>) : list.map((it, i) => {
                return (<HStack key={`list-application-item-${i}`} width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                    paddingBottom={2}>
                    <Box flex={1}>
                        <Popover>
                            <PopoverTrigger>
                                <Button size={"sm"} colorScheme="blue">Show URI</Button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                <PopoverHeader>URI</PopoverHeader>
                                <PopoverBody>:/{it._id}</PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </Box>
                    <Box flex={3}>
                        <Text fontSize={14} textColor={"gray.400"}>{it.application_name}</Text>
                    </Box>
                    <Box flex={2}>
                        <Text fontSize={14} textColor={"gray.400"}>{it.at}</Text>
                    </Box>
                    <Box flex={1}>
                        <Tag colorScheme={it.is_active ? "green" : "yellow"}>{it.is_active ? "Active" : "Inactive"}</Tag>
                    </Box>
                    <Box flex={1}>
                        <IconButton size={"sm"}
                            aria-label="Detail Action"
                            icon={<Icon as={FiEye} />}
                            colorScheme="orange"
                            onClick={() => goto(RoutePath.applications.detail.replace(":id", it._id || "-1"))} />
                    </Box>
                </HStack>)
            })} />
            
    </ComponentLayoutDashboard>);
}

export default ApplicationListPage