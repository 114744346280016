import { useState } from "react"
const useVisibility = () => {

    const [isShow, setIsShow] = useState(false);

    return {
        isShow,
        setIsShow
    }

}

export default useVisibility;