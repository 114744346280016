import { ComponentNavigationDropdown } from "components"
import React from "react"

const SalenChannel: React.FC = () => {
    return (<ComponentNavigationDropdown
        label="Kanal Penjualan"
        items={[
            { label: "Website", onItemClick: () => {} },
            { label: "Social Media", onItemClick: () => {} },
        ]} />)
}

export default SalenChannel