import { Box, Button, GridItem, HStack, Icon, SimpleGrid, Text, VStack } from "@chakra-ui/react"
import { ComponentAnnounce, ComponentCountBarMultiple, ComponentCountBarSingle, ComponentItemListType2, ComponentLayoutDashboard } from "components"
import { ContentPage } from "configs"

const DashboardPage: React.FC = (): JSX.Element => {

    return (<ComponentLayoutDashboard>

            <VStack spacing={4} width={"full"} paddingX={4} paddingY={4}>
                <Box width={"full"} textAlign={"left"}>
                    <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{ContentPage.dashboard.heading} Andy Maulana Yusuf</Text>
                </Box>
                <SimpleGrid columns={3} gap={4} width={"full"}>
                    <GridItem colSpan={2}>
                        <VStack spacing={4} width={"full"}>
                            
                            <HStack width={"full"} spacing={4}>
                                <ComponentCountBarSingle 
                                    label={ContentPage.dashboard.counting_bar.applications.label}
                                    count={"0"}
                                    icon={ContentPage.dashboard.counting_bar.applications.icon}
                                    onMore={() => {}} />

                                <ComponentCountBarSingle 
                                    label={ContentPage.dashboard.counting_bar.api_keys.label}
                                    count={"0"}
                                    icon={ContentPage.dashboard.counting_bar.api_keys.icon}
                                    onMore={() => {}} />
                            </HStack>
                            
                            <ComponentCountBarMultiple 
                                label={ContentPage.dashboard.counting_bar.devices.label}
                                description={ContentPage.dashboard.counting_bar.devices.description}
                                counts={[ 
                                    { label: "Active", value: 0 },
                                    { label: "Inactive", value: 0 },
                                    { label: "Deleted", value: 0 },
                                ]}
                                onMore={() => {}} />

                        </VStack>
                    </GridItem>
                    <GridItem>

                        <VStack spacing={4} width={"full"}>
                            
                            <ComponentAnnounce
                                title={ContentPage.dashboard.helping_widget.label}
                                description={ContentPage.dashboard.helping_widget.description}
                                content={(<Button leftIcon={<Icon as={ContentPage.dashboard.helping_widget.button.icon} />}
                                colorScheme="orange" size={"sm"}
                                width={"full"}
                                marginTop={4}>
                                    {ContentPage.dashboard.helping_widget.button.label}
                                </Button>)} />

                            <ComponentAnnounce
                                title={ContentPage.dashboard.documentation_widget.label}
                                description={ContentPage.dashboard.documentation_widget.description}
                                content={(<VStack width={"full"} spacing={2} marginTop={4}>
                                    {ContentPage.dashboard.documentation_widget.items.map((it, i) => {
                                        return (<ComponentItemListType2 
                                            key={`item-documentation-widget-${i}`}
                                            title={it.label}
                                            description={it.description}
                                            onItemClick={() => {}} />)
                                    })}
                                </VStack>)} />

                        </VStack>

                    </GridItem>
                </SimpleGrid>
            </VStack>
            
        </ComponentLayoutDashboard>)
}

export default DashboardPage