import { Box, Button, Center, FormControl, FormLabel, GridItem, HStack, Icon, IconButton, Input, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { ComponentLayoutTitle, ComponentTable, ComponentToast } from "components";
import { ContentPage, Pages } from "configs";
import { FiPlus, FiSave, FiXCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useForm, useGateway } from "./hooks";
import { useLoad } from "services";

const ApplicationFormPage: React.FC = (): JSX.Element => {

    const { id } = useParams();
    const { showMessageWarning } = ComponentToast();
    const {
        forms,
        
        setForms,
        setFormApplicationName,
        setFormLabelKey,
        setFormLabelValue,
        addFormNewLabel,
        removeFormNewLabel
    } = useForm();
    const {
        isLoading, create, update, detail: loadDetail
    } = useGateway();

    const mode = (id) ? (id === "-1") ? "new" : "edit" : "new";

    const {} = useLoad(() => {
        if (mode === "edit") {
            loadDetail((data) => {
                setForms({
                    application_name: data.application_name,
                    keys: (JSON.parse(data.keys || "") as string[]).map((it) => {
                        return it;
                    }) || [],
                    values: (JSON.parse(data.values || "") as string[]).map((it) => {
                        return it;
                    }) || []
                })
            });
        }
    });

    const onSubmit = () => {
        // Doing sanity check
        if (forms.application_name === undefined) return showMessageWarning("Please fill application name");
        else if (forms.application_name.length === 0) return showMessageWarning("Please fill application name");
        else {
            if (mode === "new") create(forms);
            else update(forms);
        }
    }

    return (<ComponentLayoutTitle title={Pages.application}>
        <VStack spacing={4} width={"full"} paddingX={4} paddingTop={4}>
            <HStack width={"full"}>
                <Box flex={1} textAlign={"left"}>
                    <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{mode === "new" ? ContentPage.applications.title.new : ContentPage.applications.title.edit}</Text>
                    <Text fontSize={14} textColor={"gray.400"}>{ContentPage.applications.description.form}</Text>
                </Box>
                <Button colorScheme="orange" size={"sm"} leftIcon={<Icon as={FiSave} />}
                    onClick={onSubmit}>Submit</Button>
            </HStack>
            <SimpleGrid gap={4} columns={2} width={"full"} borderTop={"1px solid rgba(0,0,0,0.1)"}
                paddingTop={4}>
                <GridItem>
                    <FormControl isRequired={true}>
                        <FormLabel fontWeight={"semibold"} fontSize={16} textColor={"gray.600"} padding={0} margin={0}>Application Name</FormLabel>
                        <Text fontSize={13} textColor={"gray.400"} marginBottom={2}>Insert your application name</Text>
                        <Input placeholder="Insert your application name here..." 
                            value={forms.application_name}
                            onChange={(e) => setFormApplicationName(e.target.value)} />
                    </FormControl>
                </GridItem>
            </SimpleGrid>
        </VStack>

        <ComponentTable
            title={"Labels"}
            description={"Insert the labels you want for your application"}
            header={(<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                <Box flex={3}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Keys</Text>
                </Box>
                <Box flex={3}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Value</Text>
                </Box>
                <Box flex={1}>
                    <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Action</Text>
                </Box>
            </HStack>)}
            body={(forms.keys?.length || 0) > 0 ? forms.keys?.map((key, i) => {
                const value = forms.values?.[i];

                return (<HStack key={`item-form-label-item-${i}`} width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                    <Box flex={3}>
                        <FormControl>
                            <Input placeholder="Input key here..." 
                                value={key} 
                                onChange={(e) => setFormLabelKey(i, e.target.value)} />
                        </FormControl>
                    </Box>
                    <Box flex={3}>
                        <FormControl>
                            <Input placeholder="Input value here..." 
                                value={value} 
                                onChange={(e) => setFormLabelValue(i, e.target.value)} />
                        </FormControl>
                    </Box>
                    <HStack spacing={1} flex={1}>
                        <IconButton size={"sm"}
                            aria-label="New Action"
                            icon={<Icon as={FiPlus} />}
                            colorScheme="green"
                            onClick={addFormNewLabel} />
                        <IconButton size={"sm"}
                            aria-label="Delete Action"
                            icon={<Icon as={FiXCircle} />}
                            colorScheme="red"
                            onClick={() => removeFormNewLabel(-1)} />
                    </HStack>
                </HStack>);
            }): <Center height={20} backgroundColor={"gray.50"} width={"full"}
                cursor={"pointer"}
                onClick={addFormNewLabel}>
                <HStack>
                    <Icon as={FiPlus} />
                    <Text>Create new labels</Text>
                </HStack>
            </Center>} />
    </ComponentLayoutTitle>);
}

export default ApplicationFormPage