import { Box, HStack, Text } from "@chakra-ui/react"
import { ComponentLayoutDashboard } from "components"
import { CountbarSales, QuickAction, SalenChannel } from "widgets"

const HomePage: React.FC = (): JSX.Element => {
    return (<ComponentLayoutDashboard>
        <Box flex={1} minH={"100vh"}>
            <HStack
                borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingX={4}
                paddingY={3}>
                <Box flex={1}>
                    <Text textColor={"gray.600"} fontSize={14} fontWeight={"semibold"}>Hello there, Andys Collection</Text>
                    <Text textColor={"gray.600"} fontSize={12} fontWeight={"light"}>Here is some information we gathered about your store</Text>
                </Box>
                <SalenChannel />
            </HStack>
            <CountbarSales />
            <QuickAction />
        </Box>
    </ComponentLayoutDashboard>)
}

export default HomePage