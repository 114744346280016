import { Box, HStack, Icon, Text } from "@chakra-ui/react"
import { FiChevronRight } from "react-icons/fi"

const ComponentItemListType2: React.FC<ItemList.Type2Properties> = ({
    title,
    description,
    onItemClick
}): JSX.Element => {

    return (<Box width={"full"} padding={4} backgroundColor={"gray.50"} cursor={"pointer"}
    onClick={onItemClick}>
        <HStack width={"full"}>
            <Box flex={1}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.600"}>{title}</Text>
                <Text fontSize={12} textColor={"gray.600"} fontWeight={"light"}>{description}</Text>
            </Box>
            <Icon as={FiChevronRight} fontSize={20} textColor={"gray.600"} />
        </HStack>
    </Box>)
}

export default ComponentItemListType2