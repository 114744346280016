import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ComponentSearchBar from "components/app-bar/search-bar";

const ComponentTable: React.FC<Table.Properties> = ({
    title,
    description,
    search,
    action,
    header,
    body,
}): JSX.Element => {

    return (<VStack spacing={4} width={"full"} paddingX={4} paddingY={4}>
        <HStack width={"full"} borderTop={"1px solid rgba(0,0,0,0.1)"}
            paddingTop={4}>
            <Box flex={2}>
                <Text fontWeight={"semibold"} fontSize={16} textColor={"gray.600"}>{title}</Text>
                <Text fontSize={13} textColor={"gray.400"}>{description}</Text>
            </Box>
            {search && (<Box flex={1}>
                <ComponentSearchBar
                    placeholder={search?.placeholder || ""}
                    is_can_search={true}
                    onSearch={search?.onSearch} />
            </Box>)}
            {action && (<Box>
                <Button aria-label="New Button" size={"sm"} colorScheme={"orange"}
                onClick={action?.onClick}>{action?.label}</Button>
            </Box>)}
        </HStack>
        {header}
        {body}
    </VStack>);
}

export default ComponentTable