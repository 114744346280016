import { Box, Text } from "@chakra-ui/react"

const ComponentAnnounce: React.FC<Announce.Properties> = ({
    title,
    description,
    content
}): JSX.Element => {

    return (<Box width={"full"} padding={4} boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
        <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.600"}>{title}</Text>
        <Text marginTop={1} fontSize={12} textColor={"gray.600"} fontWeight={"light"}>{description}</Text>
        {content}
    </Box>)
}

export default ComponentAnnounce