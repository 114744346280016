import { HStack } from "@chakra-ui/react";
import { ComponentCountBarSingle } from "components";
import React from "react";
import { FiBarChart2, FiShoppingBag } from "react-icons/fi";

const CountbarSales: React.FC = () => {
    
    return (<HStack
        paddingX={4}
        paddingY={3}
        spacing={4}>
        <ComponentCountBarSingle
            label="Sales"
            count={"Rp 0.00"}
            icon={FiBarChart2}
            onMore={() => {}} />
        <ComponentCountBarSingle
            label="Orders"
            count={"0"}
            icon={FiShoppingBag}
            onMore={() => {}} />
    </HStack>)
}

export default CountbarSales