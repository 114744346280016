import { Box } from "@chakra-ui/react";
import { RoutePath, goto } from "configs";
import { useEffect } from "react";
import { useData } from "storages";

const ComponentLayoutCenter: React.FC<Layout.DashboardProperties> = ({
    children
}): JSX.Element => {

    const { isLogged } = useData();

    useEffect(() => {
        if (isLogged !== undefined) {
            if (isLogged) goto(RoutePath.dashboard);
        }
    }, [isLogged])

    return (<Box 
        maxWidth={"container.lg"}
        minWidth={"container.lg"}
        marginX={"auto"}
        minHeight={"100vh"}
        position={"relative"}>
            <Box
                margin={0}
                position={"absolute"}
                top={"50%"}
                left={"50%"}
                transform={"translate(-50%, -50%)"}>
                {children}
            </Box>
    </Box>);
}

export default ComponentLayoutCenter