import { Box, Button, Center, Flex, GridItem, HStack, Icon, IconButton, SimpleGrid, Spinner, Text, VStack } from "@chakra-ui/react";
import { ComponentAlert, ComponentLayoutTitle } from "components";
import { Pages, RoutePath, goto } from "configs";
import { API } from "configs/api";
import { DeviceListPage } from "pages/devices";
import { FiCopy, FiEdit, FiXCircle } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useLoad } from "services";
import { useApplication, useGateway } from "./hooks";

const ApplicationDetailPage: React.FC = (): JSX.Element => {

    const { id } = useParams();

    const { isLoading, detail: loadDetail, remove } = useGateway();
    const { detail, setDetail } = useApplication();

    const {} = useLoad(() => {
        loadDetail((data) => {
            setDetail({
                _id: data._id,
                application_name: data.application_name,
                at: data.at,
                is_active: data.is_active,
                is_deleted: data.is_deleted,
                keys: (JSON.parse(data.keys || "") as string[]).map((it) => {
                    return it;
                }),
                values: (JSON.parse(data.values || "") as string[]).map((it) => {
                    return it;
                })
            });
        });
    });

    return (<ComponentLayoutTitle title={Pages.application}>
        { isLoading ? (<Box width={"full"} paddingX={4} paddingTop={4}>
            <Center>
                <Spinner size={"sm"} />
            </Center>
        </Box>) : (<VStack spacing={4} width={"full"} paddingX={4} paddingTop={4}>
            <HStack width={"full"} spacing={2}>
                <Box flex={1} textAlign={"left"}>
                    <Text fontWeight={"semibold"} fontSize={18} textColor={"gray.600"}>{detail?.application_name}</Text>
                    <Text fontSize={14} textColor={"gray.400"}>{"Created at "}{detail?.at}</Text>
                </Box>
                <ComponentAlert
                    title="Edit Application?"
                    description={`Are you sure want to edit application ${detail?.application_name}?`}
                    trigger={(<Button colorScheme="yellow" size={"sm"} leftIcon={<Icon as={FiEdit} />}>Edit</Button>)}
                    content={(<Button colorScheme="yellow" size={"sm"} leftIcon={<Icon as={FiEdit} />}
                    onClick={() => { goto(RoutePath.applications.form.replace(":id", id || "-1")) }}>Edit</Button>)} />
                <ComponentAlert
                    title="Remove Application?"
                    description={`Are you sure want to remove application ${detail?.application_name}? all of devices and datas from this application will be deleted to!`}
                    trigger={(<Button colorScheme="red" size={"sm"} leftIcon={<Icon as={FiXCircle} />}>Delete</Button>)}
                    content={(<Button colorScheme="red" size={"sm"} leftIcon={<Icon as={FiXCircle} />}
                    onClick={() => { remove() }}>Delete</Button>)} />
            </HStack>
            <SimpleGrid gap={4} columns={2} width={"full"} borderTop={"1px solid rgba(0,0,0,0.1)"}
                paddingTop={4}>
                <GridItem>
                    <VStack spacing={4} width={"full"}>
                        <Box width={"full"}>
                            <Text fontWeight={"semibold"} fontSize={16} textColor={"gray.500"}>{"Application Details"}</Text>
                            <Text fontSize={13} textColor={"gray.400"}>{"Discover all the details about your application below"}</Text>
                        </Box>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"Application ID"}</Text>
                            </Box>
                            <Box flex={3}>
                                <Text fontSize={13} textColor={"gray.400"}>{"/:"}{detail?._id}</Text>
                            </Box>
                        </Flex>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"Labels"}</Text>
                            </Box>
                            <Box flex={3}>
                                {detail?.keys?.map((key, i) => {
                                    const value = detail.values?.[i];
                                    return (<Text key={`label-item-${i}`} fontSize={13} textColor={"gray.400"}>{
                                        `${key} : ${value}`
                                    }</Text>)
                                })}
                            </Box>
                        </Flex>
                    </VStack>
                </GridItem>
                <GridItem>
                    <VStack spacing={4} width={"full"}>
                        <Box width={"full"}>
                            <Text fontWeight={"semibold"} fontSize={16} textColor={"gray.500"}>{"Connect via API"}</Text>
                            <Text fontSize={13} textColor={"gray.400"}>{"Seamlessly integrate your application with out API"}</Text>
                        </Box>
                        <Flex width={"full"}>
                            <Box flex={1}>
                                <Text fontWeight={"semibold"} fontSize={13} textColor={"gray.500"}>{"URI"}</Text>
                            </Box>
                            <Box flex={3}>
                                <Text fontSize={13} textColor={"gray.400"}>{API.applications.detail(id || "").substring(0, 54)}{"..."}</Text>
                            </Box>
                            <IconButton aria-label="Copy Action" size={"sm"} icon={<Icon as={FiCopy} />} colorScheme="orange"
                                onClick={() => navigator.clipboard.writeText(API.applications.detail(id || ""))} />
                        </Flex>
                    </VStack>
                </GridItem>
            </SimpleGrid>
        </VStack>) }

        <DeviceListPage />
    </ComponentLayoutTitle>);
}

export default ApplicationDetailPage