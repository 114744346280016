import { FormControl, Icon, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";

const ComponentSearchBar: React.FC<AppBar.SearchBarProperties> = ({
    placeholder,
    is_can_search,
    onSearch
}): JSX.Element => {

    return (<FormControl>
        <InputGroup size={"sm"}>
            <InputLeftElement>
                <Icon as={FaSearch} color={"gray.500"} />
            </InputLeftElement>
            <Input type={"search"} placeholder={placeholder}
                textColor={"gray.500"}
                onChange={(e) => {
                    if (is_can_search !== undefined && onSearch !== undefined) {
                        if (is_can_search) onSearch(e.target.value);
                    }
                }} />
        </InputGroup>
    </FormControl>)
}

export default ComponentSearchBar