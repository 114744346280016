import { useState } from "react";

const useForm = () => {
    const [forms, setForms] = useState<Auth.FormProperties>({
        full_name: "",
        password: "",
        username: ""
    });

    const setFormFullName = (full_name: string) => {
        const temp = {
            ...forms,
            full_name
        }

        setForms(temp);
    }

    const setFormUsername = (username: string) => {
        const temp = {
            ...forms,
            username
        }

        setForms(temp);
    }

    const setFormPassword = (password: string) => {
        const temp = {
            ...forms,
            password
        }

        setForms(temp);
    }

    return {
        forms,

        setFormFullName,
        setFormUsername,
        setFormPassword
    }
}

export default useForm;