import { Box, Button, Center, HStack, Icon, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Spinner, Tag, Text } from "@chakra-ui/react";
import { ComponentTable } from "components";
import { ContentPage, RoutePath, goto } from "configs";
import { FiEye } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { useLoad } from "services";
import { useDevice, useGateway } from "./hooks";

const DeviceListPage: React.FC = (): JSX.Element => {

    const { id } = useParams();

    const { isLoading, list: loadList } = useGateway();
    const { list, setList } = useDevice();

    const {} = useLoad(() => {
        loadList((data) => {
            setList(data);
        });
    });

    return (<ComponentTable
        title={ContentPage.devices.title.list}
        description={ContentPage.devices.description.list}
        search={{
            placeholder: ContentPage.devices.actions.search,
            onSearch: (query: string) => {}
        }}
        action={{
            label: "New",
            onClick: () => { goto(RoutePath.devices.form.replace(":application_id", id || "-1").replace(":id", "-1")) }
        }}
        header={(<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
            paddingBottom={2}>
            <Box flex={1}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>URI</Text>
            </Box>
            <Box flex={3}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Device Name</Text>
            </Box>
            <Box flex={2}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Last Update</Text>
            </Box>
            <Box flex={1}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Status</Text>
            </Box>
            <Box flex={1}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>More</Text>
            </Box>
        </HStack>)}
        body={isLoading ? (<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
        paddingBottom={2}>
            <Box flex={1}>
                <Center>
                    <Spinner size={"sm"} />
                </Center>
            </Box>
        </HStack>) : list.map((it, i) => {
            return (<HStack key={`list-device-item-${i}`} width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
                paddingBottom={2}>
                <Box flex={1}>
                    <Popover>
                        <PopoverTrigger>
                            <Button size={"sm"} colorScheme="blue">Show URI</Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader>URI</PopoverHeader>
                            <PopoverBody>:/{it._id}</PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Box>
                <Box flex={3}>
                    <Text fontSize={14} textColor={"gray.400"}>{it.device_name}</Text>
                </Box>
                <Box flex={2}>
                    <Text fontSize={14} textColor={"gray.400"}>{it.at}</Text>
                </Box>
                <Box flex={1}>
                    <Tag colorScheme={it.is_active ? "green" : "yellow"}>{it.is_active ? "Active" : "Inactive"}</Tag>
                </Box>
                <Box flex={1}>
                    <IconButton size={"sm"}
                        aria-label="Detail Action"
                        icon={<Icon as={FiEye} />}
                        colorScheme="orange"
                        onClick={() => goto(RoutePath.devices.detail.replace(":application_id", id || "-1").replace(":id", it._id || "-1"))} />
                </Box>
            </HStack>)
        })} />);
}

export default DeviceListPage