import { Icon } from "@chakra-ui/react";
import { ComponentNavigationSideBar } from "components";
import { BoxLogo } from "configs";
import React from "react";
import { FiGift, FiGrid, FiMonitor, FiSettings, FiShoppingCart, FiTag, FiUsers } from "react-icons/fi";

const Sidebar: React.FC<{ onNotificationToggle: () => void }> = ({ onNotificationToggle }) => {
    return (<ComponentNavigationSideBar
        logo={BoxLogo.toString()}
        title="Go Up Dashboard"
        items={[
            { label: "Beranda", icon: (<Icon as={FiGrid} />), isActive: true },
            { label: "Atur Produk", icon: (<Icon as={FiTag} />), isActive: false, children: [
                { label: "Produk", isActive: false },
                { label: "Kategori", isActive: false },
                { label: "Brand", isActive: false },
                { label: "Paket", isActive: false },
            ] },
            { label: "Atur Orderan", icon: (<Icon as={FiShoppingCart} />), isActive: false, children: [
                { label: "Transaksi", isActive: false },
                { label: "Kasir", isActive: false },
            ] },
            { label: "Daftar Pembeli", icon: (<Icon as={FiUsers} />), isActive: false },
            { label: "Atur Promo", icon: (<Icon as={FiGift} />), isActive: false, children: [
                { label: "Diskon", isActive: false },
                { label: "Voucher", isActive: false }
            ] },
            { label: "Konten", icon: (<Icon as={FiMonitor} />), isActive: false },
            { label: "Pengaturan", icon: (<Icon as={FiSettings} />), isActive: false },
        ]}
        onNotificationToggle={onNotificationToggle} />)
}

export default Sidebar