import { ComponentToast } from "components";
import { goback, waiting } from "configs";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAPIApplications } from "services/api";

const useGateway = () => {

    const { showMessageError, showMessageSuccess } = ComponentToast()
    
    const { applicationCreateReq, applicationUpdateReq, applicationDeleteReq, applicationDetailReq, applicationListReq } = useAPIApplications();

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    // Create Application
    const create = (form: Applications.FormProperties) => {
        setIsLoading(true);
        applicationCreateReq({
            application_name: form.application_name,
            keys: form.keys,
            values: form.values
        }, (data: APIApplications.ResponseWrite) => {
            if (data.status) {
                showMessageSuccess(data.message);
                waiting(goback);
            }
            else showMessageError(data.message);
            setIsLoading(false);
        });
    }

    // Update Application
    const update = (form: Applications.FormProperties) => {
        if (id) {
            setIsLoading(true);
            applicationUpdateReq(id, {
                application_name: form.application_name,
                keys: form.keys,
                values: form.values
            }, (data: APIApplications.ResponseWrite) => {
                if (data.status) {
                    showMessageSuccess(data.message);
                    waiting(goback);
                }
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Remove Application
    const remove = () => {
        if (id) {
            setIsLoading(true);
            applicationDeleteReq(id, (data: APIApplications.ResponseWrite) => {
                if (data.status) {
                    showMessageSuccess(data.message);
                    waiting(goback);
                }
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Detail Application
    const detail = (onLoad: (data: APIApplications.DataDetail) => void) => {
        if (id) {
            setIsLoading(true);
            applicationDetailReq(id, (data: APIApplications.ResponseDetail) => {
                if (data.status) onLoad(data.data);
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    // Detail Application
    const list = (onLoad: (data: APIApplications.DataList[]) => void) => {
        setIsLoading(true);
        applicationListReq((data: APIApplications.ResponseList) => {
            if (data.status) onLoad(data.data);
            else showMessageError(data.message);
            setIsLoading(false);
        });
    }

    return {
        isLoading,
        
        create,
        update,
        remove,
        detail,
        list
    }

}

export default useGateway;