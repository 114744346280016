import { ComponentToast } from "components";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useAPIDatas } from "services/api";

const useGateway = () => {

    const { showMessageError } = ComponentToast()
    
    const { dataListReq } = useAPIDatas();

    const { id, application_id } = useParams();

    const [isLoading, setIsLoading] = useState(false);

    // Detail Application
    const list = (onLoad: (data: APIDatas.Data[]) => void) => {
        if (application_id && id && application_id !== "-1" && id !== "-1") {
            setIsLoading(true);
            dataListReq(application_id, id, (data: APIDatas.Response) => {
                if (data.status) onLoad(data.data);
                else showMessageError(data.message);
                setIsLoading(false);
            });
        }
    }

    return {
        isLoading,
        
        list
    }

}

export default useGateway;