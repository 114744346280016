import { Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverFooter, PopoverHeader, PopoverTrigger, Portal } from "@chakra-ui/react"

const ComponentAlert: React.FC<Alert.Properties> = ({
    title,
    description,
    trigger,
    content
}): JSX.Element => {

    return (<Popover>
        <PopoverTrigger>
          {trigger}
        </PopoverTrigger>
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverHeader>{title}</PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              {description}
            </PopoverBody>
            <PopoverFooter>
                {content}
            </PopoverFooter>
          </PopoverContent>
        </Portal>
    </Popover>)
}

export default ComponentAlert