import { useState } from "react";

const useData = () => {
    const [list, setList] = useState<APIDatas.Data[]>([]);

    return {
        list, setList,
    }
}

export default useData;