import { useState } from "react";

const useDevice = () => {
    const [list, setList] = useState<APIDevices.DataList[]>([]);
    const [detail, setDetail] = useState<Devices.DetailProperties>();

    return {
        list, setList,
        detail, setDetail
    }
}

export default useDevice;