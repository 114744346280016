import { Box, HStack, Spacer, Text, VStack } from "@chakra-ui/react"
import { ComponentItemListType1 } from "components"
import { Colors } from "configs"
import React from "react"

const Notification: React.FC = () => {
    return (<Box width={"360px"} minH={"100vh"}
        borderLeft={"1px solid rgba(0,0,0,0.1)"}>
        <VStack width={"full"} paddingX={4} paddingY={3}
            borderBottom={"1px solid rgba(0,0,0,0.1)"}
            backgroundColor={Colors.gray}>
            <Box width={"full"}>
                <Text textColor={"gray.600"} fontSize={14} fontWeight={"semibold"}>Notification</Text>
            </Box>
            <HStack width={"full"}>
                <ComponentItemListType1
                    label="Semua"
                    isActive={true}
                    onItemClick={() => {}} />
                <ComponentItemListType1
                    label="Belum Dibaca"
                    isActive={false}
                    onItemClick={() => {}} />
                <Spacer />
                <Text 
                    cursor={"pointer"}
                    textColor={"gray.600"} fontSize={12} fontWeight={"light"}
                    textDecor={"underline"}>Tandai Sudah Dibaca</Text>
            </HStack>
        </VStack>
    </Box>)
}

export default Notification