import { Box, Center, HStack, Icon, IconButton, Spinner, Text } from "@chakra-ui/react";
import { ComponentTable } from "components";
import { ContentPage, RoutePath, goto } from "configs";
import { FiChevronDown } from "react-icons/fi";
import { useLoad } from "services";
import { useData, useGateway } from "./hooks";

const DatasListPage: React.FC = (): JSX.Element => {

    const { isLoading, list: loadList } = useGateway();
    const { list, setList } = useData();

    const {} = useLoad(() => {
        loadList((data) => {
            setList(data);
        });
    });

    return (<ComponentTable
        title={ContentPage.datas.title.list}
        description={ContentPage.datas.description.list}
        search={{
            placeholder: ContentPage.datas.actions.search,
            onSearch: (query: string) => {}
        }}
        action={{
            label: "Download",
            onClick: () => { goto(RoutePath.applications.form.replace(":id", "-1")) }
        }}
        header={(<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
            paddingBottom={2}>
            <Box flex={2}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Time</Text>
            </Box>
            <Box flex={3}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Resource Index (RI)</Text>
            </Box>
            <Box flex={4}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>Data</Text>
            </Box>
            <Box flex={1}>
                <Text fontSize={14} fontWeight={"semibold"} textColor={"gray.500"}>More</Text>
            </Box>
        </HStack>)}
        body={isLoading ? (<HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
        paddingBottom={2}>
            <Box flex={1}>
                <Center>
                    <Spinner size={"sm"} />
                </Center>
            </Box>
        </HStack>) : list.map((it, i) => {
            return (<HStack key={`list-data-item-${i}`} width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
            paddingBottom={2}>
            <Box flex={2}>
                <Text fontSize={14} textColor={"gray.400"}>{it.at}</Text>
            </Box>
            <Box flex={3}>
                <Text fontSize={14} textColor={"gray.400"}>:/{it._id}</Text>
            </Box>
            <Box flex={4} backgroundColor={"gray.50"} padding={2} borderRadius={4}>
                <Text fontSize={14} textColor={"gray.400"}>{it.data?.substring(0, 54)}{(it.data?.length || 0) > 54 ? "..." : ""}</Text>
            </Box>
            <Box flex={1}>
                <IconButton size={"sm"}
                    aria-label="Detail Action"
                    icon={<Icon as={FiChevronDown} />}
                    colorScheme="whiteAlpha"
                    textColor={"gray.400"}
                    onClick={() => {}} />
            </Box>
        </HStack>);
        })} />);
}

export default DatasListPage