// const baseURL = "http://localhost/amethyst/index.php";
const baseURL = "https://aitek230.online/amethyst/index.php";

export const API = {
    auth: {
        login: `${baseURL}/login`,
        registration: `${baseURL}/registration`,
    },
    users: {
        profile: `${baseURL}/profile`,
        update_profile: `${baseURL}/profile`,
        development: `${baseURL}/profile/development`,
    },
    applications: {
        create: `${baseURL}/applications`,
        list: `${baseURL}/applications`,
        update: (_id: string) => `${baseURL}/applications/${_id}`,
        delete: (_id: string) => `${baseURL}/applications/${_id}`,
        detail: (_id: string) => `${baseURL}/applications/${_id}`,
    },
    devices: {
        create: (application_id: string) => `${baseURL}/applications/${application_id}/devices`,
        list: (application_id: string) => `${baseURL}/applications/${application_id}/devices`,
        update: (application_id: string, _id: string) => `${baseURL}/applications/${application_id}/devices/${_id}`,
        delete: (application_id: string, _id: string) => `${baseURL}/applications/${application_id}/devices/${_id}`,
        detail: (application_id: string, _id: string) => `${baseURL}/applications/${application_id}/devices/${_id}`,
    },
    datas: {
        create: (application_id: string, device_id: string) => `${baseURL}/applications/${application_id}/devices/${device_id}/datas`,
        list: (application_id: string, device_id: string) => `${baseURL}/applications/${application_id}/devices/${device_id}/datas`,
    }
}