import { useState } from "react";

const useApplication = () => {
    const [list, setList] = useState<APIApplications.DataList[]>([]);
    const [detail, setDetail] = useState<Applications.DetailProperties>();

    return {
        list, setList,
        detail, setDetail
    }
}

export default useApplication;