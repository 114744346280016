import { Box, HStack, Text } from "@chakra-ui/react"

import { MultipleProperties } from "types/count-bar"

const ComponentCountBarMultiple: React.FC<MultipleProperties> = ({
    label,
    description,
    counts,
    onMore
}): JSX.Element => {

    return (<Box width={"full"} padding={4} boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
        <HStack width={"full"} borderBottom={"1px solid rgba(0,0,0,0.1)"}
            paddingBottom={4}
            marginBottom={4}>
            <Box flex={1}>
                <Text fontSize={16} fontWeight={"semibold"} textColor={"gray.500"}>{label}</Text>
                <Text fontSize={13} textColor={"gray.400"}>{description}</Text>
            </Box>
            <Text fontSize={13} textColor={"gray.400"} cursor={"pointer"} onClick={onMore}>See more details</Text>
        </HStack>
        <HStack width={"full"}>
            {counts?.map((it, i) => {
                return (<Box flex={1} key={`item-count-bar-${label}-${i}`}>
                    <Text fontSize={16} fontWeight={"semibold"} textColor={"gray.500"}>{it.label}</Text>
                    <Text fontSize={24} fontWeight={"semibold"} textColor={"gray.600"}>{it.value}</Text>
                </Box>)
            })}
        </HStack>
    </Box>)
}

export default ComponentCountBarMultiple