import { API } from "configs/api";
import baseRequest from "services/base_api";
import { useData } from "storages";

const useAPIDatas = () => {
    const { getToken } = useData();

    // Create Datas
    const dataCreateReq = (application_id: string, device_id: string, body: APIDatas.Request, callback: (data: APIDatas.ResponseWrite) => void) => {
        baseRequest({ 
            url: API.datas.create(application_id, device_id),
            content: {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
                body: JSON.stringify(body)
            },
            onSuccess: (result) => {
                const data: APIDatas.ResponseWrite = JSON.parse(result) as APIDatas.ResponseWrite;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    // List Datas
    const dataListReq = (application_id: string, device_id: string, callback: (data: APIDatas.Response) => void) => {
        baseRequest({ 
            url: API.datas.list(application_id, device_id),
            content: {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `${getToken()}`
                },
            },
            onSuccess: (result) => {
                const data: APIDatas.Response = JSON.parse(result) as APIDatas.Response;
                // Callback
                callback(data);
            },
            onFailure(error) {
                console.log(error);
            },
        })
    }

    return {
        dataCreateReq,
        dataListReq
    }
}

export default useAPIDatas