import { Box, Flex } from "@chakra-ui/react";
import { useVisibility } from "utilities";
import { Notification, Sidebar } from "widgets";

const ComponentLayoutDashboard: React.FC<Layout.DashboardProperties> = ({
    children
}): JSX.Element => {

    const { isShow: isShowNotification, setIsShow: setIsShowNotification } = useVisibility();
    // const { isLogged } = useData();

    // useEffect(() => {
    //     if (isLogged !== undefined) {
    //         if (!isLogged) goto(RoutePath.auth.login);
    //     }
    // }, [isLogged])

    const onNotificationToggle = () => {
        if (isShowNotification) setIsShowNotification(false);
        else setIsShowNotification(true);
    }

    return (<Box 
        width={"full"}
        marginX={"auto"}
        minHeight={"100vh"}
        backgroundColor={"white"}
        boxShadow={"0px 0px 4px rgba(0,0,0,0.1)"}>
            <Flex width={"full"}>
                <Sidebar onNotificationToggle={onNotificationToggle} />
                {children}
                { isShowNotification && <Notification /> }
            </Flex>
    </Box>);
}

export default ComponentLayoutDashboard